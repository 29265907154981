import React, { useEffect, useState, useRef } from "react";
import {
  Circle,
  Container,
  ContentContainer,
  HomeLink,
  ImageContainer,
  LinkLabel,
  RobotContainer,
} from "./NotFoundStyled";
import { GlobalStyle } from "src/styles/globalStyles";
import { Flex } from "src/styles/components";
import { Title } from "components/typography";
import { HelmetComponent } from "components/helmet";
import notFound404 from "../../assets/images/404.png";
import robot from "../../assets/images/robot.png";

const NotFound = () => {
  const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setIntersecting(true);
    }, 100);
    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    <Container>
      <HelmetComponent />

      <GlobalStyle />

      <HomeLink to="/">
        <LinkLabel>Home</LinkLabel>

        <div>
          <Circle className={isIntersecting ? "" : "start"} />
          <Circle className={isIntersecting ? "" : "start"} />
          <Circle className={isIntersecting ? "" : "start"} />
        </div>
      </HomeLink>

      <Flex>
        <RobotContainer className="robot-desktop">
          <img src={robot} alt="robot" />
        </RobotContainer>

        <ContentContainer>
          <ImageContainer>
            <img src={notFound404} alt="404" />
          </ImageContainer>
          <RobotContainer className="robot-mobile">
            <img src={robot} alt="robot" />
          </RobotContainer>
          <Title
            title="Hang on! We are Under maintenance"
            subTitle="It will not take a long time till we get the system update."
          />
        </ContentContainer>
      </Flex>
    </Container>
  );
};

export default NotFound;
