import styled, { css } from "styled-components";

import { theme } from "src/styles/global";
import { Link } from "gatsby";

export const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  background: linear-gradient(
    320deg,
    rgba(67, 67, 107, 0) 56.04%,
    #43436b 100%
  );

  @media (${theme.screenSize.mobile}) {
    align-items: flex-end;
    padding-bottom: 185px;
  }
`;

export const ContentContainer = styled.section`
  position: relative;
  margin-left: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (${theme.screenSize.tablet}) {
    margin-left: 0;
    max-width: 100vw;
    padding: 0 10vw;

    h1,
    span {
      text-align: center;
    }

    span {
      display: inherit;
    }
  }
`;

export const H1 = styled.h1`
  font-family: "Dinish", sans-serif;
  font-size: 80px;
  font-weight: 700;
  color: ${theme.colors.magnolia};
  margin: 20px 0 0;
  line-height: 0.9;
  max-width: 760px;
  text-transform: uppercase;

  @media (${theme.screenSize.tablet}) {
    font-size: 60px;
  }

  @media (${theme.screenSize.mobile}) {
    font-size: 40px;
  }
`;

export const Text = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: ${theme.colors.graySuit};
  margin: 20px 0 0;
  max-width: 360px;
`;

export const RobotContainer = styled.div`
  &.robot-mobile {
    display: none;
  }

  @media (${theme.screenSize.tablet}) {
    &.robot-desktop {
      display: none;
    }

    &.robot-mobile {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -365px;
    }
  }

  @media (${theme.screenSize.mobile}) {
    &.robot-mobile {
      top: -308px;
    }
  }

  & img {
    max-height: 377px;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 4px;
  left: -44px;
  top: 25px;
  z-index: -1;

  & img {
    max-height: 148px;
  }

  @media (${theme.screenSize.tablet}) {
    left: 50%;
    transform: translateX(-50%);
    top: -180px;
  }

  @media (${theme.screenSize.mobile}) {
    top: -210px;

    & img {
      max-height: unset;
      height: 200px;
    }
  }
`;

const drawCircle = (size, opacity) => css`
  transition: all 1.6s cubic-bezier(0.14, 0.48, 0, 0.96);
  border: 1px solid rgba(255, 255, 255, ${opacity});
  width: ${size}px;
  height: ${size}px;
  /* bottom: -${size / 2}px; */

  &.start {
    width: 0px;
    height: 0px;
  }
`;

export const Circle = styled.span`
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(1);

  &:nth-child(1) {
    ${drawCircle(280, 0.5)}
    top: -35px;
  }

  &:nth-child(2) {
    ${drawCircle(364, 0.3)}
    top: -70px;
  }

  &:nth-child(3) {
    ${drawCircle(444, 0.05)}
    top: -105px;
  }

  @media (${theme.screenSize.tablet}) {
    &:nth-child(1) {
      ${drawCircle(230, 0.5)}
    }

    &:nth-child(2) {
      ${drawCircle(294, 0.3)}
    }

    &:nth-child(3) {
      ${drawCircle(344, 0.05)}
    }
  }

  @media (${theme.screenSize.mobile}) {
    &:nth-child(1) {
      ${drawCircle(217, 0.5)}
    }

    &:nth-child(2) {
      ${drawCircle(279, 0.3)}
    }

    &:nth-child(3) {
      ${drawCircle(341, 0.05)}
    }
  }
`;

export const HomeLink = styled(Link)`
  background: white;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  bottom: -100px;
  transform: translateX(-50%);

  &:hover {
    ${Circle} {
      transform: translateX(-50%) scale(0);
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 400px;
    height: 400px;
    left: -50%;
    top: -50%;
  }

  @media (${theme.screenSize.tablet}) {
    width: 160px;
    height: 160px;
    bottom: -80px;
  }

  @media (${theme.screenSize.mobile}) {
    width: 150px;
    height: 150px;
    bottom: -75px;
  }

  &:hover {

  }
`;

export const LinkLabel = styled.span`
  color: ${theme.colors.black};
  font-family: "Dinish", sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;

  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
`;

